<template>
  <div style="padding-top: 60px">
    <div
      style="width: 1200px; min-width: 1200px; height: 88px; margin: 0px auto"
    >
      <div class="logo-class" :style="{ backgroundImage: 'url(' + img + ')' }">
        <div
          style="
            display: inline-block;
            margin-left: 720px;
            margin-top: 20px;
            font-size: 1.4rem;
            color: #00448f;
            text-shadow: 2px 2px 2px #fff, -2px -2px 2px #fff;
            font-weight: bold;
          "
        >
          人事人才测评服务专栏
        </div>
      </div>
      <div class="search">
        <input
          class="search-input"
          type="text"
          id="txt_SearchAll"
          name="txt_SearchAll"
          placeholder="请输入你的关键字"
          value=""
          v-model="keyword"
        />
        <button type="button" class="btn search-btn" @click="serchNews">
          <i class="iconfont icon-mb-search mr-1"></i>搜索
        </button>
      </div>
    </div>
    <div class="navBar">
      <ul class="navBar-ul">
        <a
          href="javascript:;"
          v-for="(item, index) in navList"
          :key="index"
          @click="changeClass(item, index)"
          :class="{
            active: activeIndex == index,
          }"
        >
          <li>{{ item.name }}</li>
        </a>
        <a href="http://rsks.class.com.cn" target="_blank">
          <li>网上购书</li>
        </a>
      </ul>
      <div class="navBar-right d-flex">
        <!-- <div class="navBar-right-box navBar-border-right">
          <img src="@/assets/images/index/icon-bag.png">
          <div class="mt-2"> 网上商城 </div>
        </div> -->
        <div class="navBar-right-box">
          <img src="@/assets/images/index/icon-person.png" />
          <div class="" @click="showModal = true"> 登录/注册</div>
        </div>
      </div>
    </div>

    <b-modal
        v-model="showModal"
        title="温馨提示"
        hide-footer
        centered
        class="custom-width-modal"
    >

      <div>
        <h3>关于四川省人力资源和社会保障厅-人事人才测评服务专栏-个人后台接入四川人社在线公共服务平台的提示</h3>
        <div class="my-4" >

          <p>
            各位考生：
          </p>

          <p style=" text-indent: 2em;">
            四川人事考试基地网用户认证模块将于2024年7月19并入四川人社在线公共服务平台。升级完成后，四川人事考试基地网将正式更名为：四川省人力资源和社会保障厅（<a href="https://rst.sc.gov.cn" target="_blank">rst.sc.gov.cn</a>）“人事人才测评服务”专栏（<a href="https://www.jdpta.com" target="_blank">https://www.jdpta.com</a>）。
          </p>
          <p style=" text-indent: 2em;">
            届时，点击“登录/注册”按钮，您将自动跳转至四川人社在线公共服务平台。已在该服务平台注册的考生可继续使用原账号登录，未注册的考生请先下载四川人社APP完成注册，再进行登录。
          </p>
          <p style=" text-indent: 2em;">登录和注册问题请查看四川人社APP登录文档，登录成功后相关报名、准考证打印、成绩查询等业务问题请咨询四川省人事人才考试测评基地：028-86740101，或网站“在线咨询”转“人工客服”。
          </p>
          <p style=" text-indent: 2em;">详细通知内容及附件：<a href="https://www.jdpta.com/newsDetails?newsId=E3598FAEFF8CCB0C" target="_blank">https://www.jdpta.com/newsDetails?newsId=E3598FAEFF8CCB0C</a>
          </p>
        </div>
        <div style="text-align: right;margin-top: 20px;">
          <el-button size="mini" type="primary" @click="openLogin">前往登录</el-button>
          <el-button size="mini" type="danger" @click="openRegister">注册</el-button>
        </div>
      </div>
    </b-modal>
  </div>

</template>

<script>
import img from "@/assets/images/logos.png";

export default {
  name: "index",
  data() {
    return {
      showModal: false,
      img,
      activeIndex: 0,
      navList: [
        {
          name: "网站首页",
          url: "/index",
        },
        {
          name: "通知公告",
          url: "/newsList",
        },
        {
          name: "考试专题",
          url: "/examTypeList",
        },
        {
          name: "政策法规",
          url: "/special",
        },
        {
          name: "对外服务",
          url: "/intro",
        },
        {
          name: "常见问题",
          url: "/questionList",
        },
      ],
      keyword: "",
    };
  },
  methods: {
    openLogin() {
      window.open("https://www.schrss.org.cn/ssologin/login?service=https%3A%2F%2Fersuserapi.jdpta.com%2Fyethan%2Flogin%2Fcas");
    },
    openRegister() {
      window.open("https://www.schrss.org.cn/authfront/auth/userRegisterAction.do?service=https%3A%2F%2Fersuserapi.jdpta.com%2Fyethan%2Flogin%2Fcas");
    },
    changeClass(obj, index) {
      window.location.href = obj.url;
      this.activeIndex = index;
    },

    /**
     * 跳转新闻页面并搜索
     */
    serchNews() {
      var keyword = this.keyword.replace(/\s*/g, "");
      if (keyword != "") {
        let routeUrl = this.$router.resolve({
          path: "/newsList",
          query: {
            keyword: keyword,
          },
        });
        window.open(routeUrl.href, "_self");
      }
    },
  },
  created() {
    let url = window.location.href;
    if (url.includes("examList")) {
      this.activeIndex = 0;
      return;
    }
    if (url.includes("examList")) {
      this.activeIndex = "";
      return;
    }
    if (url.includes("newsList") || url.includes("newsDetail")) {
      this.activeIndex = 1;
      return;
    }
    if (url.includes("examTypeList") || url.includes("examPlan")) {
      this.activeIndex = 2;
      return;
    }
    if (url.includes("special")) {
      this.activeIndex = 3;
      return;
    }
    if (url.includes("intro")) {
      this.activeIndex = 4;
      return;
    }
    if (url.includes("questionList")) {
      this.activeIndex = 5;
      return;
    }
    // for (const key in this.navList) {
    //   if (url.includes(this.navList[key].url)) {
    //     this.activeIndex = key
    //     return
    //   }
    // }
  },
};
</script>
<style>
.modal-dialog {
  max-width: 760px !important; /* 设置模态框的最大宽度 */
}
p{
  margin-bottom: 0px;
  line-height: 25px;
}
h3{
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}
</style>
<style scoped>

.logo-class {
  height: 88px;
  display: inline-block;
  background-repeat: no-repeat;
}
.top_login {
  display: inline-block;
  float: right;
  margin-right: 10px;
  padding-top: 8px;
  color: #004652;
  font-size: 20px;
}
.top_login a {
  text-decoration: none;
  font-size: 14px;
  color: #32619d;
}
.navBar {
  width: 1200px;
  min-width: 1200px;
  height: 60px;
  margin: 0px auto;
  background: #328ad1;
  margin-top: 55px;
  text-align: left;
}
.navBar-ul a:hover {
  background-color: #3269a7;
}
.navBar-ul .active {
  background-color: #00448f;
}
</style>
